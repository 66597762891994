

import * as React from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { Html, Loader as DreiLoader, OrbitControls, PerspectiveCamera, Stars, useProgress } from "@react-three/drei";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useGesture } from "@use-gesture/react";
import { MathUtils, Vector3 } from "three";
import { Bloom, DepthOfField, EffectComposer } from "@react-three/postprocessing";
import { LightBox } from "../../components/layout/lightbox";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setShowInstructions } from "../../store/galaxySlice";
import SEO from "../../components/layout/seo";
import { Loader } from "../../components/three/common/Loader";
const Galaxy = React.lazy(() => import ("../../components/three/galaxy/galaxy"));

const GalaxyPageContent : React.FC =() => {

    const { camera } = useThree();

    React.useEffect(() => {
        if(camera == null) return;
        camera.position.set(-1600,1000,0);
    }, [camera]);

    return (
        <>
            <React.Suspense fallback={null}>
                <Galaxy />
            </React.Suspense>
        </>
    )
}

const GalaxyPage : React.FC = () => {

    const {width, height} = useWindowSize();
    const showInstructions = useSelector<RootState, boolean>(state => state.galaxy.showInstructions);
    const dispatch = useDispatch();

    if(width == 0 || height == 0) {
        return (<></>);
    }

    
    return (
            <div style={{width: width, height: height, backgroundColor: "#330022"}}>

            <SEO    
                title="Star Stories"
                image="/images/seo/starstories.png"
                description="Explore a galaxy full of culture and history."
            />

                <LightBox show={showInstructions}
                    onClose={() => {
                        dispatch(setShowInstructions({show: false}));
                    }}
                >
                    <div>
                        <h1>Explore the Galaxy_</h1>
                        <ol>
                            <li>Click a star to see its planets.</li>
                            <li>Click a planet to hear its story.</li>
                            <li>Drag to move around your starship.</li>
                        </ol>
                        <small><em>(Works best on desktop.)</em></small>
                    </div>
                </LightBox>

                <Canvas>
                    <color attach="background" args={["#330022"]} />
                    <OrbitControls minDistance={100} maxDistance={2500} target={[0,0,0]} />
                    <ambientLight />

                    <PerspectiveCamera makeDefault far={5000} />

                    <Stars radius={1000} depth={1} count={1000} factor={4} saturation={0} speed={1} />

                    <EffectComposer>
                        <DepthOfField focusDistance={1} focalLength={0.5} bokehScale={1} height={height} />
                        <Bloom luminanceThreshold={0} luminanceSmoothing={1} height={height} />
                    </EffectComposer>     

                    <GalaxyPageContent />

                </Canvas>
                <DreiLoader />
            </div>
    );  
}

export default GalaxyPage;